import './style.css';

import circleMeme1Img from './metadata/memesCircle/1.jpeg';
import circleMeme2Img from './metadata/memesCircle/2.jpeg';
import circleMeme3Img from './metadata/memesCircle/3.jpeg';
import circleMeme4Img from './metadata/memesCircle/4.jpeg';
import circleMeme5Img from './metadata/memesCircle/5.jpeg';
import circleMeme6Img from './metadata/memesCircle/6.jpeg';
import circleMeme7Img from './metadata/memesCircle/7.jpeg';
import circleMeme8Img from './metadata/memesCircle/8.jpeg';

import video1Mp4 from './metadata/memeScroll/1.gif';
import video2Mp4 from './metadata/memeScroll/2.gif';
import video3Mp4 from './metadata/memeScroll/3.gif';

import audioMp3 from './metadata/memeScroll/1.mp3';

const landingPage = document.getElementById('landingPage');
landingPage.style.display = 'block';

const closeLandingBtn = document.createElement('button');
closeLandingBtn.id = "closeLandingBtn";
closeLandingBtn.innerHTML = `ENTER BUTT`;
landingPage.appendChild(closeLandingBtn);

const mainPage = document.getElementById('mainPage'); 
mainPage.style.display = 'none';

const memeContainer = document.getElementById('memeContainer');

const memeScroll = document.createElement('div');
memeScroll.id = 'memeScroll';
const gif1 = document.createElement('img');
gif1.src = video1Mp4;
const gif2 = document.createElement('img');
gif2.src = video2Mp4; 
const gif3 = document.createElement('img');
gif3.src = video3Mp4;
memeScroll.appendChild(gif1);
memeScroll.appendChild(gif2);
memeScroll.appendChild(gif3);
memeContainer.appendChild(memeScroll);

const memesCircle = document.createElement('div');
memesCircle.id = 'memesCircle';
let currentMemeIndex = 0;
function flyingMemes() {
  const circleMeme1 = document.createElement('img');
  circleMeme1.src = circleMeme1Img;
  const circleMeme2 = document.createElement('img');
  circleMeme2.src = circleMeme2Img;
  const circleMeme3 = document.createElement('img');
  circleMeme3.src = circleMeme3Img;
  const circleMeme4 = document.createElement('img');
  circleMeme4.src = circleMeme4Img;
  const circleMeme5 = document.createElement('img');
  circleMeme5.src = circleMeme5Img;
  const circleMeme6 = document.createElement('img');
  circleMeme6.src = circleMeme6Img;
  const circleMeme7 = document.createElement('img');
  circleMeme7.src = circleMeme7Img;
  const circleMeme8 = document.createElement('img');
  circleMeme8.src = circleMeme8Img;
  const collection = [circleMeme1, circleMeme2, circleMeme3, circleMeme4, circleMeme5, circleMeme6, circleMeme7, circleMeme8];
  const selectedMeme = collection[currentMemeIndex];
  selectedMeme.classList.add("flyingMeme");
  memesCircle.appendChild(selectedMeme);
  currentMemeIndex = (currentMemeIndex + 1) % collection.length;
}

memeContainer.appendChild(memesCircle);

const main = document.querySelector('main');

const headerH1 = document.createElement('h1');
headerH1.innerHTML = `$BUTTSEX`;
main.appendChild(headerH1);

const socials = document.createElement('div');
socials.id = 'socials';
const telegramBtn = document.createElement('button');
telegramBtn.id = 'telegramBtn';
telegramBtn.innerHTML = "Telegram";
socials.appendChild(telegramBtn);
const twitterBtn = document.createElement('button');
twitterBtn.id = 'twitterBtn';
twitterBtn.innerHTML = "Twitter";
socials.appendChild(twitterBtn);
const chartBtn = document.createElement('button');
chartBtn.id = 'chartBtn';
chartBtn.innerHTML = "Chart";
socials.appendChild(chartBtn);
const buyBtn = document.createElement('button');
buyBtn.id = 'buyBtn';
buyBtn.innerHTML = "Buy";
socials.appendChild(buyBtn); 
main.appendChild(socials);

const contract = document.createElement('span')
contract.id = 'contract';
contract.innerHTML = `BFiT5ASiDiZ3gTM35tf47opjPPyPgMoTUoqQUFy7pump`;
main.appendChild(contract);

const copyCaBtn = document.createElement('button');
copyCaBtn.id = 'copyCaBtn';
copyCaBtn.innerHTML = `Copy Contract`;
main.appendChild(copyCaBtn);

document.addEventListener('DOMContentLoaded', function() {

  const audio = new Audio(audioMp3);
  audio.loop = true;
  audio.volume = 1.0;

  closeLandingBtn.addEventListener('click', function() {
    audio.play().catch(error => {
      console.log("Autoplay was prevented. Please interact with the page to play audio.", error);
    });
    landingPage.style.display = 'none';
    mainPage.style.display = 'block';
  });

  setInterval(flyingMemes, 2000);

  twitterBtn.addEventListener('click', function() {
    window.open('https://x.com/BUTTSEXCOIN', '_blank');
  });
  telegramBtn.addEventListener('click', function() {
    window.open('https://t.me/+CxGYcyPKZAVkMmQ0', '_blank');
  });
  chartBtn.addEventListener('click', function() {
    window.open('https://dexscreener.com/solana/cl86hewi5vwmw9aqqy9cxte2ecyvnxybhua1jazhykfw', '_blank');
  });
  buyBtn.addEventListener('click', function() {
    window.open('https://raydium.io/swap?outputMint=BFiT5ASiDiZ3gTM35tf47opjPPyPgMoTUoqQUFy7pump', '_blank');
  });

  const textToCopy = contract.innerHTML;
  copyCaBtn.addEventListener("click", async () => {
    try {
      // Try the modern Clipboard API first (if supported)
      await navigator.clipboard.writeText(textToCopy);
      console.log("Text copied successfully using Clipboard API");
      alert("Contract copied successfully!");
    } catch (err) {
      // If Clipboard API fails, use the legacy approach
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed"; // Hide element off-screen
      textArea.style.left = "-9999px";
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      console.log("Text copied successfully using legacy approach");
      alert("Contract copied successfully!");
    }
  });

});